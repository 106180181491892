import React from 'react'
import Link from 'gatsby-link'

import { ArrowForward } from '@material-ui/icons'

const LinkButton = ({ title, page }) => {
  return (
    <div className="button">
      <Link to={`/${page}`}>{title}</Link> <ArrowForward className="button__arrow" />
    </div>
  )
}

export { LinkButton }