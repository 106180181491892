import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

//Components
import Layout from '../components/layout'
import SEO from '../components/seo'
import { LinkButton } from '../components/_parts/LinkButton'

//Svg icons
import Sass from '../svg/sass.svg'
import Reacticon from '../svg/reacticon.svg'
import Ruby from '../svg/ruby.svg'
import Adobexd from '../svg/adobexd.svg'


export default () => {
  const queryData = useStaticQuery(graphql`
    {
      allCompetence {
        edges {
          node {
            name
            tags
          }
        }
      }
    }
  `)

  const competences = queryData.allCompetence.edges
  const blockElements = competences.map(edge => {
    const { name, tags } = edge.node
    let svgComponent

    switch (name) {
      case "Intégration web":
        svgComponent = <Sass />
        break
      case "Programmation front-end":
        svgComponent = <Reacticon />
        break
      case "Programmation back-end":
        svgComponent = <Ruby />
        break
      case "Design UI/UX":
        svgComponent = <Adobexd />
        break
      default:
        svgComponent = <div></div>
        break
    }

    return (
      <div className="competences__bloc">
        {svgComponent}
        <h3>{name}</h3>
        <div className="competences__bloc__tags">
          {tags.map(tag => (
            <span>#{tag} </span>
          ))}
        </div>
      </div>
    )
  })

  return (
    <Layout className="page-competences">
      <SEO title="Compétences" />
      <h1 className="page-title">
        <span className="page-title__hollow-bold">Compétences</span>
        <br />
        <span className="page-title__red-line">
          Ce dont je suis capable <span className="page-title__dot black">.</span>
        </span>
      </h1>
      <hr style={{ border: "solid #B8B8B8 1px", width: "100%", margin: "0 auto" }} />
      <div className="competences">
        {blockElements}
      </div>
      <LinkButton title="VOIR MES MEILLEURS PROJETS" page="projets" />
    </Layout>
  )
}